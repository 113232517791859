@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

* {
  box-sizing: border-box;
  /* transition: all 0.35s; */
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}

#root,
html {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  font-size: 11.66px;
}

@media only screen and (min-height: 720px) {
  #root,
  html {
    font-size: 8px;
  }
}

@media only screen and (min-height: 768px) {
  #root,
  html {
    font-size: 9.7px;
  }
}

@media only screen and (min-height: 900px) {
  #root,
  html {
    font-size: 11.66px;
  }
}

@media only screen and (min-height: 1080px) {
  #root,
  html {
    font-size: 14px;
  }
}

/*

@media only screen and (min-height: 1152px) { 
    #root,
    html {
      font-size: 14.93px;
    }
} 

@media only screen and (min-height: 1440px) { 
    #root,
    html {
      font-size: 18.66px;
    }
} 

@media only screen and (min-height: 1800px) { 
    #root,
    html {
      font-size: 23.33px;
    }
} 

@media only screen and (min-height: 2160px) { 
    #root,
    html {
      font-size: 28px;
    }
}  */

.react-international-phone-country-selector-button {
  height: 2.714rem
}
