.collapsedSection{
	height: 0rem !important;
	overflow: hidden;
	pointer-events: none;
	box-sizing: border-box;
	padding: 0rem !important;
	margin-bottom: 0rem !important;
    opacity: 0 !important;
}
	.collapsedSection *{
		height: 0rem !important;
		overflow: hidden;
		pointer-events: none;
		box-sizing: border-box;
		padding: 0;
		font-size: 0.01px;
	}
	.collapsedSection div button{
		height: 0rem !important;
		overflow: hidden;
		pointer-events: none;
		box-sizing: border-box;
		border: none;
		padding: 0;
		font-size: 0.01px;
	}

.collapsingSection{
    opacity: 1;
	transition: all 0.5s;
}

.collapsingSection *{
	transition: all 0.5s;
	transition-delay: 0.2s;
}
	.collapsingSection div button{
		transition: all 0.5s;
		transition-delay: 0.25s;
	}